const assetStatuses = {
  review: "PendingReview",
  approved: "Reviewed",
  pending: "UploadPending",
  success: "UploadSuccessful",
  error: "UploadError",
  awaiting: "AwaitingUpload",
  uploaded: "Uploaded",
  retake: "RequiresRetake",
  pendingNotification: "PendingNotification",
  appraisal: "AppraisalImage",
};

const assetStatusMessages = {
  review: "Pending Review",
  retake: "Requires Retake",
  uploaded: "Ready to Send",
  pendingNotification: "New - Send Notification",
  appraisal: "Appraisal Image",
  approved: "Approved",
};

const truckConditions = {
  new: "New",
  used: "Used",
  wholesale: "Wholesale",
  appraisal: "Appraisal",
};

export { assetStatuses, assetStatusMessages, truckConditions };
