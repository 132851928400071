import { useToggle } from "react-use";
import { toast } from "react-toastify";
import dayjs from "dayjs";

import Button from "../Button";

import {
  assetStatuses as statuses,
  truckConditions as truckStatuses,
} from "../../constants/statuses";
import { useConfirm } from "../../hooks/useConfirm";
import { usePhotos } from "../../hooks/usePhotos";
import { usePhotoContainer } from "../../hooks/usePhotoContainer";
import { useRoles } from "../../hooks/useRoles";
import { splitFilename } from "../../services/images";
import {
  deleteImages,
  downloadImages,
  bulkUpdateImageDetails,
  syncTruckToWebsite, convertToAppraisal,
} from "../../services/truckAssets";
import {usePhotoUsage} from "../../hooks/usePhotoUsage";

export default function InventoryActions({ className, visibleAssets }) {
  const [isApproving, toggleIsApproving] = useToggle(false);
  const [isDownloading, toggleIsDownloading] = useToggle(false);
  const [isDeleting, toggleIsDeleting] = useToggle(false);
  const [isConverting, toggleIsConverting] = useToggle(false);
  const [isSendingForReview, toggleIsSendingForReview] = useToggle(false);
  const [isRequestingRetake, toggleIsRequestingRetake] = useToggle(false);
  const [isNotifying, toggleIsNotifying] = useToggle(false);

  const { rolesMap, checkAccess } = useRoles();

  const { isConfirmed } = useConfirm();

  const { photoContainers } = usePhotoContainer();

  const getApprovableAssets = () =>
    visibleAssets
      .filter((container) => container.assets && container.assets.length > 0)
      .flatMap((container) => container.assets)
      .filter(
        (asset) =>
          asset.status === statuses.review || asset.status === statuses.uploaded
      );

  const getReviewableAssets = () =>
    visibleAssets
      .filter((container) => container.assets && container.assets.length > 0)
      .flatMap((container) => container.assets)
      .filter((asset) => asset.status === statuses.uploaded);

  const getPendingNotificationAssets = () =>
    visibleAssets
      .filter((container) => container.assets && container.assets.length > 0)
      .flatMap((container) => container.assets)
      .filter((asset) => asset.status === statuses.pendingNotification);

  const {
    clearSelectedItems,
    selectedItems,
    refetch,
    truckDetails: { condition, vin, make, model, year },
  } = usePhotos();
  const { isAppraisalUsage } = usePhotoUsage();

  const onApproveForWeb = async () => {
    if (isApproving) {
      return;
    }

    let itemsToUpdate;

    if (selectedItems.length > 0) {
      itemsToUpdate = selectedItems.filter(
        (item) =>
          item.status === statuses.review || item.status === statuses.uploaded
      );
    } else {
      itemsToUpdate = getApprovableAssets();
    }

    if (itemsToUpdate.length === 0) {
      return;
    }

    const noAltMultiples = itemsToUpdate
      .filter((item) => {
        const matchingContainer = photoContainers.find(
          (container) => container.id === item.containerId
        );

        return "numberAllowed" in matchingContainer;
      })
      .filter((item) => {
        return !item.altText;
      });

    if (noAltMultiples.length > 0) {
      await isConfirmed(
        "noAltText",
        noAltMultiples.map((item) => item.fileName)
      );

      return;
    }

    itemsToUpdate = itemsToUpdate.map((item) => {
      if (item.altText) {
        return item;
      }

      const thisContainer = photoContainers.find(
        (container) => container.id === item.containerId
      );

      let altText = "";
      let fileName = item.fileName;
      let originalFilename = splitFilename(item.fileName);

      if (thisContainer) {
        if (thisContainer.numberAllowed) {
          fileName = `${vin}-${year}-${make}-${model}-${dayjs(
            new Date()
          ).format("YYYY-MM-DDTHHmmss")}.${originalFilename.extension}`;
        }

        if (thisContainer.description) {
          altText = thisContainer.description
            .replace("{Year}", year)
            .replace("{Make}", make)
            .replace("{Model}", model)
            .replace("{Vin}", vin);
        }

        if (thisContainer.name) {
          fileName = thisContainer.name
            .replace("{Year}", year)
            .replace("{Make}", make)
            .replace("{Model}", model)
            .replace("{Vin}", vin);

          fileName = `${fileName}.${originalFilename.extension}`;
        }
      }

      return { ...item, fileName, altText };
    });

    toggleIsApproving(true);

    try {
      const response = await bulkUpdateImageDetails(
        vin,
        itemsToUpdate.map((item) => ({
          Id: item.id,
          AltText: item.altText,
          Status: statuses.approved,
          FileName: item.fileName,
          Tags: item.tags || [],
        }))
      );

      refetch(vin);
      clearSelectedItems();
    } catch (err) {
      console.error(err);

      toast.error("There was an error approving assets.");
    } finally {
      toggleIsApproving(false);
    }
  };

  const onSendForReview = async () => {
    if (isSendingForReview) {
      return;
    }

    toggleIsSendingForReview(true);

    let itemsToUpdate = selectedItems.filter(
      (item) => item.status === statuses.uploaded
    );

    if (itemsToUpdate.length === 0) {
      itemsToUpdate = getReviewableAssets();
    }

    try {
      const response = await bulkUpdateImageDetails(
        vin,
        itemsToUpdate.map((item) => ({
          Id: item.id,
          AltText: item.altText,
          Status: statuses.review,
          FileName: item.fileName,
          Tags: item.tags || [],
        }))
      );

      console.log(response);

      refetch(vin);
      clearSelectedItems();
    } catch (err) {
      console.error(err);

      toast.error("There was an error sending assets for approval.");
    } finally {
      toggleIsSendingForReview(false);
    }
  };

  const onDownloadAll = async () => {
    if (isDownloading) {
      return;
    }

    let itemsToDownload = selectedItems;

    toggleIsDownloading(true);

    if (selectedItems.length === 0) {
      itemsToDownload = visibleAssets
        .filter((container) => container.assets && container.assets.length > 0)
        .flatMap((container) => container.assets);
    }

    try {
      await downloadImages(
        vin,
        itemsToDownload.map((asset) => asset.id)
      );

      clearSelectedItems();
    } catch (err) {
      console.error(err);

      toast.error("There was an error downloading assets.");
    } finally {
      toggleIsDownloading(false);
    }
  };

  const onSyncToWebsite = async () => {
    const confirmed = await isConfirmed("sync");

    if (confirmed) {
      try {
        await syncTruckToWebsite(vin);
      } catch (err) {
        console.error(err);
        toast.error("There was an error syncing the truck to the website.");
      }
    }
  };

  const onApproveAll = async () => {
    if (isApproving) {
      return;
    }

    let itemsToApprove = approvablePhotos;

    if (itemsToApprove.length === 0) {
      return;
    }

    const confirmed = await isConfirmed("approve");

    if (confirmed) {
      toggleIsApproving(true);

      try {
        await bulkUpdateImageDetails(
          vin,
          itemsToApprove.map((item) => ({
            Id: item.id,
            FileName: item.fileName,
            AltText: item.altText.trim(),
            Tags: item.tags,
            Status: statuses.approved,
          }))
        );

        refetch(vin);
      } catch (err) {
        console.error(err);

        toast.error("There was an error approving assets.");
      } finally {
        toggleIsApproving(false);
      }
    }
  };

  const onDeleteAll = async () => {
    if (isDeleting || selectedItems.length === 0) {
      return;
    }

    const confirmed = await isConfirmed(
      "delete",
      selectedItems.map((item) => item.fileName)
    );

    if (confirmed) {
      toggleIsDeleting(true);

      try {
        await deleteImages(
          vin,
          selectedItems
            .filter((item) => item.status !== statuses.approved)
            .map((item) => item.id)
        );

        refetch(vin);

        clearSelectedItems();
      } catch (err) {
        console.error(err);

        toast.error("There was an error deleting assets.");
      } finally {
        toggleIsDeleting(false);
      }
    }
  };

  const onRequestRetake = async () => {
    if (isRequestingRetake) {
      return;
    }

    toggleIsRequestingRetake(true);

    let itemsToUpdate = selectedItems;

    if (itemsToUpdate.length === 0) {
      itemsToUpdate = visibleAssets
        .filter((container) => container.assets && container.assets.length > 0)
        .flatMap((container) => container.asset);
    }

    try {
      const response = await bulkUpdateImageDetails(
        vin,
        itemsToUpdate.map((item) => ({
          Id: item.id,
          AltText: item.altText,
          Status: statuses.retake,
          FileName: item.fileName,
          Tags: item.tags || [],
        }))
      );

      console.log(response);

      clearSelectedItems();
    } catch (err) {
      console.error(err);

      toast.error("There was an error requesting retakes.");
    } finally {
      toggleIsRequestingRetake(false);
    }
  };

  const onNotifyAppraisal = async () => {
    if (isNotifying) {
      return;
    }

    toggleIsNotifying(true);

    let itemsToUpdate = getPendingNotificationAssets();

    if (itemsToUpdate.length === 0) {
      toast.error("No assets to update");

      return;
    }

    try {
      const response = await bulkUpdateImageDetails(
        vin,
        itemsToUpdate.map((item) => ({
          ID: item.id,
          AltText: item.altText,
          Status: statuses.appraisal,
          FileName: item.fileName,
          Tags: item.tags || [],
        }))
      );

      if (response.status === 204) {
        refetch(vin);

        await isConfirmed("notificationSent");
      }
    } catch (err) {
      console.error("There was an error sending assets for appraisal");

      toast.error("There was an error sending assets for appraisal");
    } finally {
      toggleIsNotifying(false);
    }
  };

  const onAppraisalConvert = async () => {
    toggleIsConverting(true);

    try {
      await convertToAppraisal(vin);
      await refetch(vin);
    } catch (err) {
      console.error(err);
      toast.error("There was an error converting images to appraisal images.");
    }
    finally {
      toggleIsConverting(false);
    }
  }

  const approvableSelections = selectedItems.filter(
    (item) =>
      item.status === statuses.review || item.status === statuses.uploaded
  );
  const reviewableSelections = selectedItems.filter(
    (item) => item.status === statuses.uploaded
  );
  const retakeableSelections = selectedItems.filter(
    (item) =>
      item.status !== statuses.approved && item.status !== statuses.retake
  );
  const deleteableSelections = selectedItems.filter(
    (item) => item.status !== statuses.approved
  );

  if (isAppraisalUsage === undefined && checkAccess([rolesMap.salesSap])) {
    return <></>
  }

  return (
    <div className={className || "truck-images__upload-actions"}>
      {!isAppraisalUsage &&
        checkAccess([rolesMap.sales, rolesMap.salesSap]) && (
          <Button
            types={["filled"]}
            onClick={onSendForReview}
            isLoading={isSendingForReview}
            isDisabled={
              getReviewableAssets().length === 0 ||
              (selectedItems.length > 0 && reviewableSelections.length === 0)
            }
          >
            Send{" "}
            {reviewableSelections.length > 0
              ? `${reviewableSelections.length}`
              : "all"}{" "}
            for review
          </Button>
        )}

      {!isAppraisalUsage &&
        checkAccess([rolesMap.admin, rolesMap.marketing]) && (
          <Button
            types={["filled"]}
            onClick={onApproveForWeb}
            isLoading={isApproving}
            isDisabled={
              getApprovableAssets().length === 0 ||
              (selectedItems.length > 0 && approvableSelections.length === 0)
            }
          >
            Approve{" "}
            {approvableSelections.length > 0
              ? `${approvableSelections.length}`
              : "all"}{" "}
            for web
          </Button>
        )}

      {isAppraisalUsage && (
        <Button
          types={["filled"]}
          onClick={onNotifyAppraisal}
          isLoading={isNotifying}
          isDisabled={getPendingNotificationAssets().length === 0}
        >
          Send Appraisal Image
        </Button>
      )}

      {!isAppraisalUsage &&
        checkAccess([rolesMap.admin, rolesMap.marketing]) && (
          <Button
            types={["outline"]}
            isDisabled={
              selectedItems.length === 0 ||
              (selectedItems.length > 0 && retakeableSelections.length === 0)
            }
            onClick={onRequestRetake}
            isLoading={isRequestingRetake}
          >
            Request{" "}
            {retakeableSelections.length > 0
              ? `${retakeableSelections.length} `
              : ""}
            retake{retakeableSelections.length > 1 ? "s" : ""}
          </Button>
        )}

      <Button
        types={["outline"]}
        icon="download"
        onClick={onDownloadAll}
        isLoading={isDownloading}
      >
        Download{" "}
        {selectedItems.length > 0 ? `${selectedItems.length} selected` : "all"}
      </Button>

      {checkAccess([rolesMap.admin, rolesMap.marketing]) && (
        <Button types={["outline"]} onClick={onAppraisalConvert} isLoading={isConverting}>
          Convert all images to appraisal
        </Button>
      )}

      {checkAccess([rolesMap.admin, rolesMap.marketing]) && !isAppraisalUsage && (
        <Button types={["outline"]} onClick={onSyncToWebsite}>
          Sync Truck to Website
        </Button>
      )}

      <Button
        types={["outline", "trash"]}
        isDisabled={
          selectedItems.length === 0 ||
          (selectedItems.length > 0 && deleteableSelections.length === 0)
        }
        onClick={onDeleteAll}
        icon="trash"
        isLoading={isDeleting}
      />

      {selectedItems.length > 0 && (
        <Button
          types={["text", "text-small"]}
          onClick={() => clearSelectedItems()}
        >
          Clear selected
        </Button>
      )}
    </div>
  );
}
