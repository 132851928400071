import Lightbox from 'react-image-lightbox';

import { useLightbox } from '../../hooks/useLightbox';

import 'react-image-lightbox/style.css';

const customStyles = {
	overlay: {
		zIndex: 3000
	}
};

export default function ImageLightbox() {
	const {
		imageUrls,
		imageCaptions,
		activeLightbox,
		closeLightbox,
		setActiveLightbox
	} = useLightbox();

	if ( activeLightbox < 0 ) {
		return <></>;
	}

	return (
		<Lightbox
			mainSrc={ imageUrls[ activeLightbox ] }
			nextSrc={ imageUrls[ ( activeLightbox + 1 ) % imageUrls.length ] }
			prevSrc={ imageUrls[ ( activeLightbox + imageUrls.length - 1 ) % imageUrls.length ] }
			onCloseRequest={ () => closeLightbox() }
			enableZoom={ false }
			imageCaption={ imageCaptions[ activeLightbox ] }
			onMovePrevRequest={ () => {
				setActiveLightbox( ( activeLightbox + imageUrls.length - 1 ) % imageUrls.length );
			}}
			onMoveNextRequest={ () => {
				setActiveLightbox( ( activeLightbox + 1 ) % imageUrls.length );
			}}
			reactModalStyle={ customStyles }
		/>
	);
}
