import classNames from 'classnames/bind';

import styles from './Tag.module.scss';

const cn = classNames.bind( styles );

export function TagList({
	children
}) {
	return (
		<div className={ cn( 'tag-list' ) }>
			{ children }
		</div>
	);
}

export default function Tag({
	value
}) {
	return (
		<div className={ cn( 'tag' ) }>
			{ value }
		</div>
	);
}
