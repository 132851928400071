import Select from 'react-select';

import { availableTags } from '../../constants/tags';

export default function TagSelector( props ) {
	const customStyles = {
		control: ( provided ) => ({
			...provided,
			borderRadius: 0
		}),
		valueContainer: ( provided ) => ({
			...provided,
			padding: '12px 15px'
		}),
		placeholder: ( provided ) => ({
			...provided,
			color: 'rgba(34, 43, 62, 0.6)',
			fontSize: '14px'
		})
	};

	return (
		<Select
			{ ...props }
			styles={ customStyles }
			isMulti
			name="tags"
			options={ availableTags }
			placeholder="Start typing or open the menu to add a tag..."
			className="tag-select-container"
			classNamePrefix="tag-select"
		/>
	);
}
