import { useEffect, useMemo } from "react";
import { useSetState } from "react-use";
import classNames from "classnames/bind";
import Loading from "react-loading";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";

import Button from "../Button";
import { EditForm, EditInput } from "../EditForm";
import Notice from "../Notice";
import TruckPhoto from "../TruckPhoto";

import {
  assetStatuses as statuses,
  truckConditions as truckStatuses,
} from "../../constants/statuses";
import { useConfirm } from "../../hooks/useConfirm";
import { useSingleUpload } from "../../hooks/useSingleUpload";
import { formatDate, getPrettyFilesize } from "../../services/format";
import { splitFilename } from "../../services/images";
import {
  deleteImage,
  downloadImages,
  updateImageDetails,
} from "../../services/truckAssets";

import styles from "./Edit.module.scss";

const cn = classNames.bind(styles);

function getTagObjects(tags) {
  return tags.map((tag) => ({ label: tag, value: tag }));
}

async function updateAsset(
  vin,
  photoDetails,
  setPhotoDetails,
  refetch,
  cb = () => {}
) {
  console.log("updating:", photoDetails);

  setPhotoDetails({ isLoading: true });

  try {
    const fileName = `${photoDetails.fileName}.${photoDetails.extension}`;

    const saveDetails = await updateImageDetails(vin, photoDetails.assetId, {
      FileName: fileName.trim(),
      AltText: photoDetails.altText.trim(),
      Tags: photoDetails.tags.map((tag) => tag.value.trim()),
      Status: photoDetails.status,
    });

    console.log("updateAsset:saveDetails", saveDetails);

    if (saveDetails.status === 204) {
      await refetch(vin);

      cb();
    }
  } catch (err) {
    console.error("There was an error updating this asset:", err);

    toast.error("There was an error updating this asset.");
  } finally {
    setPhotoDetails({ ...photoDetails, isLoading: false });
  }
}

function saveAsset(vin, photoDetails, setPhotoDetails, refetch) {
  updateAsset(vin, photoDetails, setPhotoDetails, refetch);
}

async function approveAsset(
  vin,
  photoDetails,
  setPhotoDetails,
  refetch,
  isConfirmed
) {
  const confirmed = await isConfirmed("approve", [photoDetails.fileName]);

  if (!confirmed) {
    return;
  }

  const newDetails = { ...photoDetails, status: statuses.approved };

  updateAsset(vin, newDetails, setPhotoDetails, refetch);
}

function requestRetake(vin, photoDetails, setPhotoDetails, refetch, cb) {
  const newDetails = { ...photoDetails, status: statuses.retake };

  updateAsset(vin, newDetails, setPhotoDetails, refetch, cb);
}

async function onDownload(vin, assetId, setPhotoDetails) {
  setPhotoDetails({ isLoading: true });

  try {
    const downloadRequest = await downloadImages(vin, [assetId]);

    console.log(downloadRequest);
  } catch (err) {
    console.error("There was an error downloading this asset:", err);

    toast.error("There was an error downloading this asset.");
  } finally {
    setPhotoDetails({ isLoading: false });
  }
}

async function onDelete(
  vin,
  photoDetails,
  isConfirmed,
  setPhotoDetails,
  onClose,
  refetch
) {
  console.log("onDelete:", vin, photoDetails);

  const confirmed = await isConfirmed("delete", [photoDetails.fileName]);

  if (!confirmed) {
    return;
  }

  setPhotoDetails({ isLoading: true });

  try {
    await deleteImage(vin, photoDetails.assetId);

    await refetch(vin);

    onClose();
  } catch (err) {
    setPhotoDetails({ isLoading: false });

    console.error("There was an error deleting this asset:", err);

    toast.error("There was an error deleting this asset.");
  }
}

function Filesize({ size }) {
  return <>{getPrettyFilesize(size)}</>;
}

const emptyPhotoDetails = {
  fileName: "",
  extension: "",
  tags: "",
  altText: "",
  assetId: "",
  status: "",
  isLoading: false,
};

export default function Edit({
  condition,
  vin,
  make,
  model,
  year,
  container = {},
  asset: serverAsset,
  onClose,
  refetch,
}) {
  const [photoDetails, setPhotoDetails] = useSetState(emptyPhotoDetails);
  const { asset: uploadedAsset, startUpload, clearUpload } = useSingleUpload();

  const asset = useMemo(
    () => (uploadedAsset.status ? uploadedAsset : serverAsset),
    [uploadedAsset, serverAsset]
  );

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      fileName: asset ? splitFilename(asset.fileName).filename : "",
      altText: asset ? asset.altText : "",
    },
  });
  const { isConfirmed } = useConfirm();

  const onSave = handleSubmit(async (data) => {
    const mergedDetails = { ...photoDetails, ...data };

    saveAsset(vin, mergedDetails, setPhotoDetails, refetch);
  });

  const onApprove = handleSubmit(async (data) => {
    const mergedDetails = { ...photoDetails, ...data };

    approveAsset(vin, mergedDetails, setPhotoDetails, refetch, isConfirmed);
  });

  const onRequestRetake = async (data) => {
    const mergedDetails = { ...photoDetails, ...data };

    requestRetake(vin, mergedDetails, setPhotoDetails, refetch, function () {
      onClose();
    });
  };

  const onDrop = async (acceptedFiles) => {
    startUpload(acceptedFiles[0], vin, container?.id, container?.tags);
  };

  useEffect(() => {
    if (uploadedAsset.status === statuses.success) {
      clearUpload();
    }
  }, [uploadedAsset, clearUpload]);

  useEffect(() => {
    if (!serverAsset) {
      return;
    }

    setPhotoDetails({
      fileName: splitFilename(serverAsset.fileName).filename,
      extension: splitFilename(serverAsset.fileName).extension,
      tags: serverAsset.tags ? getTagObjects(serverAsset.tags) : [],
      altText: !serverAsset.altText ? "" : serverAsset.altText,
      assetId: serverAsset.id,
      status: serverAsset.status,
    });
  }, [serverAsset, setPhotoDetails]);

  const fileSize = asset?.fileSize;

  if (!asset) {
    return <></>;
  }

  return (
    <div className={cn("edit", { "edit--loading": photoDetails.isLoading })}>
      {photoDetails.isLoading && (
        <div className={cn("edit__loader")}>
          <Loading type="spin" color="#676767" width={64} height={64} />
        </div>
      )}

      <div className={cn("edit__inner")}>
        <h1 className={cn("edit__title")}>
          {vin} {(year || make) && <>&ndash;</>} {year} {make} {model}
        </h1>

        <div className={cn("edit__header")}>
          <h2 className={cn("edit__location")}>{container.location}</h2>

          <div className={cn("edit__template")}>
            {splitFilename(asset.fileName).filename}
          </div>
        </div>

        <div className={cn("edit__media")}>
          <TruckPhoto
            asset={asset}
            onDrop={onDrop}
            allowUploadCancel={false}
            showPlaceholder={false}
            hidePhotoFunctions={asset?.isUpload}
          />
        </div>

        {asset.isVertical && (
          <Notice type="warning">
            <p>
              Images should be taken in landscape orientation, rather than
              portrait.{" "}
              <Button
                types={["text"]}
                onClick={() => {
                  onRequestRetake(vin, photoDetails, setPhotoDetails, refetch);
                }}
              >
                Request retake
              </Button>
            </p>
          </Notice>
        )}

        <div className={cn("edit__details")}>
          <EditForm
            onApprove={onApprove}
            onCancel={onClose}
            onDelete={() => {
              onDelete(
                vin,
                photoDetails,
                isConfirmed,
                setPhotoDetails,
                onClose,
                refetch
              );
            }}
            onDownload={() => {
              onDownload(vin, photoDetails.assetId, setPhotoDetails);
            }}
            onRequestRetake={onRequestRetake}
            onSave={onSave}
            status={photoDetails.status}
            condition={condition}
          >
            <EditInput
              label="Name"
              id="fileName"
              register={register}
              errors={errors}
              required
              value={`${photoDetails.fileName}.${photoDetails.extension}`}
              isReadonly={
                photoDetails.status === statuses.approved ||
                condition === truckStatuses.appraisal
              }
            />

            {fileSize && (
              <EditInput
                id={`photo-filesize-${photoDetails.assetId}`}
                label="Size"
                value={<Filesize size={fileSize} />}
                isReadonly={true}
              />
            )}

            <EditInput
              label="Tags"
              type="tags"
              id={`photo-tags-${photoDetails.assetId}`}
              value={photoDetails.tags}
              onChange={(tags) => setPhotoDetails({ tags })}
              isReadonly={true}
            />

            <EditInput
              label="Alt Text"
              id="altText"
              register={register}
              required
              errors={errors}
              value={photoDetails.altText}
              isReadonly={
                photoDetails.status === statuses.approved ||
                condition === truckStatuses.appraisal
              }
            />

            {asset.createdOn && asset.createdBy && (
              <EditInput
                type="note"
                note={
                  <>
                    Added <strong>{formatDate(asset.createdOn)}</strong> by{" "}
                    <strong>{asset.createdBy}</strong>
                    {asset.updatedOn && asset.updatedBy && (
                      <>
                        , Updated <strong>{formatDate(asset.updatedOn)}</strong>{" "}
                        by <strong>{asset.updatedBy}</strong>
                      </>
                    )}
                  </>
                }
              />
            )}
          </EditForm>
        </div>
      </div>
    </div>
  );
}
