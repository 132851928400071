import { useCallback } from 'react';
import { useToggle } from 'react-use';
import classNames from 'classnames/bind';

import Button from '../Button';
import Icon from '../Icon';

import { assetStatuses as statuses } from '../../constants/statuses';
import { useRoles } from '../../hooks/useRoles';

import styles from './Filters.module.scss';

const cn = classNames.bind( styles );

function FilterDropdown({
	id,
	label,
	options,
	setFilter,
	currentFilters,
	containers
}) {
	const [ isDropdownActive, toggleIsDropdownActive ] = useToggle( false );

	const isSelected = () => currentFilters.findIndex( filter => filter.id === id ) >= 0;
	const getSelectedOption = () => {
		const selectedOption = currentFilters.find( filter => filter.id === id );

		if ( selectedOption ) {
			const matchedOption = options.find( option => option.value === selectedOption.value );

			return matchedOption;
		}

		return null;
	};

	const doesOptionHaveResults = ( id, value ) => {
		let photos = [ ...containers ];
		let filterOne = photos;
		let filterTwo = photos;

		const otherFilter = currentFilters.filter( filter => filter.id !== id );

		if ( otherFilter.length ) {
			filterOne = photos.filter( container => {
				if ( otherFilter[ 0 ].id === 'tags' && container.tags ) {
					return container.tags.includes( otherFilter[ 0 ].value );
				}

				if ( otherFilter[ 0 ].id === 'status' ) {
					if ( otherFilter[ 0 ].value === 'missing' ) {
						return !container.assets || container.assets.length === 0;
					}

					if ( container?.assets.length > 0 ) {
						return container.assets[ 0 ].status === otherFilter[ 0 ].value;
					}
				}
			});
		}

		filterTwo = filterOne.filter( container => {
			if ( id === 'tags' && container.tags ) {
				return container.tags.includes( value );
			}

			if ( id === 'status' ) {
				if ( value === 'missing' ) {
					return !container.assets || container.assets.length === 0;
				}

				if ( container?.assets.length > 0 ) {
					return container.assets[ 0 ].status === value;
				}
			}
		});

		return filterTwo.length > 0;
	}

	const selectedOption = getSelectedOption();

	return (
		<div
			className={ cn( 'filter-dropdown',
				{ 'filter-dropdown--active': isDropdownActive },
				{ 'filter-dropdown--set': isSelected() }
			)}>
			<div
				className={ cn( 'filter-dropdown__label' ) }
				onClick={ () => toggleIsDropdownActive() }
				role="button"
			>
				{ !selectedOption && (
					<>
						{ label }
					</>
				)}

				{ selectedOption && (
					<>
						{ selectedOption.label }
					</>
				)}

				<Icon icon="arrow-down" width={ 18 } height={ 18 } />
			</div>

			{ isDropdownActive && (
				<>
					<div className={ cn( 'filter-dropdown__backdrop' ) } onClick={ () => toggleIsDropdownActive( false ) } />

					<div className={ cn( 'filter-dropdown__options' ) }>
						{ options.map( option => {
							const isOptionActive = doesOptionHaveResults( id, option.value );

							return (
								<div
									className={ cn( 'filter-dropdown__option', { 'filter-dropdown__option--inactive': !isOptionActive } ) }
									key={ option.value }
									role="button"
									onClick={ () => {
										if ( !isOptionActive ) {
											return;
										}

										setFilter( id, option.value );
										toggleIsDropdownActive( false );
									}}
								>
									{ option.label }
								</div>
							)
						})}
					</div>
				</>
			)}
		</div>
	);
}

export default function Filters({
	currentFilters,
	setFilter,
	clearFilters,
	containers,
	tags
}) {
	const { rolesMap, checkAccess } = useRoles();

	const tagOptions = useCallback( () => ( tags.map( tag => ({ 'label': tag, 'value': tag }) ) ), [ tags ] );

	const statusOptions = useCallback( () => [
		{ 'label': 'Approved', 'value': statuses.approved },
		{ 'label': 'Pending review', 'value': statuses.review },
		{ 'label': 'Ready to send', 'value': statuses.uploaded },
		{ 'label': checkAccess( rolesMap.marketing ) ? 'Retake requested' : 'Retake required', 'value': statuses.retake },
		{ 'label': 'Missing', 'value': 'missing' }
	], [ checkAccess, rolesMap ] );

	return (
		<>
			<FilterDropdown label="Status" id="status" containers={ containers } options={ statusOptions() } setFilter={ setFilter } currentFilters={ currentFilters } />
			<FilterDropdown label="Tagged" id="tags" containers={ containers } options={ tagOptions() } setFilter={ setFilter } currentFilters={ currentFilters } />

			{ currentFilters.length > 0 && (
				<Button types={[ 'text', 'text-small' ]} onClick={ clearFilters }>Clear filters</Button>
			)}
		</>
	);
}
