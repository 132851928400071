import classNames from 'classnames/bind';

import styles from './Notice.module.scss';

const cn = classNames.bind( styles );

export default function Notice({
	type = 'info',
	children
}) {
	return (
		<div className={ cn( 'notice', `notice--${ type }` ) }>
			{ children }
		</div>
	);
}
