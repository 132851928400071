import { useCallback, useEffect, useMemo, useState } from "react";
import { useToggle } from "react-use";
import classNames from "classnames/bind";

import Button from "../Button";
import Drawer from "../Drawer";
import Dropzone from "../Dropzone";
import Edit from "../Edit";
import Filters from "../Filters";
import FullWidth from "../FullWidth";
import Icon from "../Icon";
import InventoryActions from "../InventoryActions";
import Lightbox from "../Lightbox";
import PageReadOnly from "../PageReadOnly";
import TruckPhoto from "../TruckPhoto";
import Wizard from "../Wizard";

import {
  assetStatuses as statuses,
  truckConditions as truckStatuses,
} from "../../constants/statuses";
import { useLightbox } from "../../hooks/useLightbox";
import { useMultipleUploads } from "../../hooks/useMultipleUploads";
import { usePhotoContainer } from "../../hooks/usePhotoContainer";
import { useRoles } from "../../hooks/useRoles";
import { usePhotos } from "../../hooks/usePhotos";
import { useSingleUpload } from "../../hooks/useSingleUpload";
import { useUploads } from "../../hooks/useUploads";
import {generateFeaturedImage, updateImageDetails} from "../../services/truckAssets";

import styles from "./TruckImages.module.scss";
import { toast } from "react-toastify";
import {roles} from "../../constants/roles";

const cn = classNames.bind(styles);

function TruckAlt({
  vin,
  fileName,
  status,
  tags,
  assetId,
  altText,
  isEditable = false,
}) {
  const [alt, setAlt] = useState(altText);
  const { refetch } = usePhotos();

  const saveAltText = useCallback(async () => {
    const updateResponse = await updateImageDetails(vin, assetId, {
      FileName: fileName,
      AltText: alt,
      Status: status,
      Tags: tags,
    });

    if (updateResponse.status === 204) {
      refetch(vin);
    }
  }, [fileName, alt, status, tags, vin, assetId, refetch]);

  const editable = (
    <div className={cn("truck-image__alt")}>
      <label htmlFor={`${assetId}-alt`}>Short descriptive name</label>
      <input
        id={`${assetId}-alt`}
        type="text"
        value={alt}
        onChange={(event) => setAlt(event.target.value)}
        onBlur={saveAltText}
      />
    </div>
  );

  const uneditable = <p className={cn("truck-image__detail")}>{altText}</p>;

  return <>{isEditable ? editable : uneditable}</>;
}

export function TruckImage({
  condition,
  location,
  containerId,
  containerTags = [],
  samples,
  vin,
  asset: serverAsset,
  setCurrentlyEditing,
  showFilename = false,
  showAltEdit = false,
  styles = {},
  generationOptions
}) {
  const { asset: uploadedAsset, startUpload, clearUpload } = useSingleUpload();
  const { setSelectedItem, unsetSelectedItem, isItemSelected, generate } =
    usePhotos();
  const { rolesMap, checkAccess } = useRoles();

  const asset = useMemo(
    () => (uploadedAsset.status ? uploadedAsset : serverAsset),
    [uploadedAsset, serverAsset]
  );

  // component functions
  const onDrop = useCallback(
    (acceptedFiles) => {
      startUpload(acceptedFiles[0], vin, containerId, containerTags);
    },
    [startUpload, vin, containerId, containerTags]
  );

  const handleCheck = useCallback(
    (checked) => {
      if (!asset.id) {
        return;
      }

      if (checked) {
        setSelectedItem(asset);
      } else {
        unsetSelectedItem(asset);
      }
    },
    [setSelectedItem, unsetSelectedItem, asset]
  );

  const onEditClick = useCallback(() => {
    setCurrentlyEditing({ containerId, assetId: asset.id });
  }, [setCurrentlyEditing, containerId, asset?.id]);

  // some other stuff
  const isSelected = asset ? isItemSelected(asset) : false;

  useEffect(() => {
    if (uploadedAsset.status === statuses.success) {
      clearUpload();
    }
  }, [uploadedAsset.status, clearUpload]);

  return (
    <div style={styles} className={cn("truck-image", {
      ["truck-image__disabled"]: !!generationOptions && (generationOptions?.disabled || !generationOptions?.isAllowed)
    })}>
      <TruckPhoto
        asset={asset}
        onDrop={onDrop}
        samples={samples}
        containerId={containerId}
        className="truck-image__media--grid"
        hidePhotoFunctions={asset?.isUpload}
        generationOptions={!!generationOptions ? {...generationOptions, onGenerate: () => generate(vin)} : undefined}
      />

      <div className={cn("truck-image__inner")}>
        <div className={cn("truck-image__details")}>
          <h3 className={cn("truck-image__name")}>
            {showFilename ? asset.fileName : location}
          </h3>

          {asset && !showFilename && !generationOptions?.isGenerated && (
            <p className={cn("truck-image__detail")}>{asset.fileName}</p>
          )}

          {!!generationOptions && generationOptions.allowRegenerate && (
            <p style={{ fontSize: '12px', color: 'rgb(224, 60, 63)' }}>Featured Image does not match 30-degree Driver Side, please regenerate.</p>
          )}

          {generationOptions?.disabled && generationOptions?.isAllowed && generationOptions?.isGenerated && (
            <p style={{ fontSize: '12px', color: 'rgb(224, 60, 63)' }}>Please upload a 30-degree Driver Side image to generate</p>
          )}

          {!generationOptions?.isGenerated && (<TruckAlt
            vin={vin}
            assetId={asset?.id}
            status={asset?.status}
            fileName={asset?.fileName}
            tags={asset?.tags}
            altText={asset?.altText}
            isEditable={
              asset?.status !== statuses.approved &&
              condition !== truckStatuses.appraisal &&
              showAltEdit &&
              checkAccess([rolesMap.sales, rolesMap.salesSap])
            }
          />)}

          {asset?.isVertical && (
            <div className={cn("truck-image__alert")}>
              <Icon icon="alert" /> Portrait orientation
            </div>
          )}
        </div>

        {asset && !asset.isUpload && !generationOptions?.disabled && (
          <div className={cn("truck-image__footer")}>
            {checkAccess([rolesMap.marketing, rolesMap.admin]) && (
              <Button
                types={["outline", "outline-primary", "small"]}
                onClick={onEditClick}
              >
                {asset.status !== statuses.approved &&
                condition !== truckStatuses.appraisal
                  ? "Edit"
                  : "View Details"}
              </Button>
            )}

            <div
              className={cn("truck-image__check")}
              onClick={() => handleCheck(!isSelected)}
            >
              <input
                type="checkbox"
                checked={isSelected}
                onChange={(event) => handleCheck(event.target.checked)}
              />

              <span
                className={cn("selected-note", {
                  "selected-note--active": isSelected,
                })}
              >
                {isSelected ? "Selected" : "Select"}
              </span>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

function TruckMultiImage({
  condition,
  vin,
  id,
  tags,
  location,
  setCurrentlyEditing,
  assets,
}) {
  const [isUploading, setIsUploading] = useState(false);
  const [isDropzoneOpen, toggleIsDropzoneOpen] = useToggle(true);
  const { refetch } = usePhotos(vin);

  const {
    clearUploads,
    startUploads,
    assets: uploadedAssets,
  } = useMultipleUploads(vin, id, tags);

  const onDrop = (acceptedFiles) => {
    setIsUploading(true);

    startUploads(acceptedFiles);
  };

  useEffect(() => {
    if (!isUploading || uploadedAssets.length === 0) {
      return;
    }

    const pendingAssets = uploadedAssets.filter(
      (asset) =>
        asset.status === statuses.awaiting || asset.status === statuses.pending
    );

    if (pendingAssets.length === 0) {
      setIsUploading(false);

      clearUploads();

      refetch(vin);
    }
  }, [uploadedAssets, refetch, vin, isUploading, clearUploads]);

  const totalAssets = [...assets, ...uploadedAssets];

  return (
    <div className={cn("inventory-section")}>
      <div className="container">
        <div className={cn("inventory-section__header")}>
          <h3
            className={cn(
              "inventory-section__title",
              "header--lvl-2",
              "header--dash"
            )}
          >
            {location}
          </h3>

          <div className={cn("inventory-section__actions")}>
            <Button
              types={["filled", "small"]}
              isDisabled={isDropzoneOpen}
              onClick={() => toggleIsDropzoneOpen(true)}
            >
              Add images
            </Button>
          </div>
        </div>
      </div>

      {isDropzoneOpen && (
        <FullWidth>
          <div className={cn("inventory-section", "inventory-section--padded")}>
            <Dropzone containerId={id} onDrop={onDrop} />

            <Button
              types={["outline"]}
              onClick={() => toggleIsDropzoneOpen(false)}
            >
              Cancel
            </Button>
          </div>
        </FullWidth>
      )}

      {totalAssets.length > 0 && (
        <div className={cn("container")}>
          <div className={cn("photo-grid", "photo-grid--multi")}>
            {totalAssets.length > 0 &&
              totalAssets.map((asset, index) => (
                <TruckImage
                  condition={condition}
                  key={asset.id || index}
                  containerId={id}
                  containerTags={tags}
                  vin={vin}
                  location={location}
                  asset={asset}
                  setCurrentlyEditing={setCurrentlyEditing}
                  showFilename={true}
                  showAltEdit={true}
                />
              ))}
          </div>
        </div>
      )}
    </div>
  );
}

function isAssetVisible(activeFilters, container) {
  // show all if no filters are active
  if (activeFilters.length === 0) {
    return true;
  }

  // don't show if it doesn't have a container object
  if (!container) {
    return false;
  }

  // check for active filter values
  const tagFilter = activeFilters.find((filter) => filter.id === "tags");
  const statusFilter = activeFilters.find((filter) => filter.id === "status");

  let matchesTag = true;
  let matchesStatus = true;

  if (tagFilter) {
    // if it doesn't have tags, it doesn't match tags
    if (!container.tags) {
      matchesTag = false;
    } else {
      matchesTag = container.tags
        .map((tag) => tag.toLowerCase())
        .includes(tagFilter.value.toLowerCase());
    }
  }

  if (statusFilter) {
    // if it doesn't have assets, it doesn't match status
    if (!container.assets || container.assets.length === 0) {
      matchesStatus = statusFilter.value === "missing";
    } else {
      matchesStatus = container.assets[0].status === statusFilter.value;
    }
  }

  return matchesTag && matchesStatus;
}

function mapPhotos(photoContainers, assets) {
  return photoContainers.map((container) => {
    let existingObject = { ...container, assets: [] };

    const matchedAssets = assets
      ?.filter((asset) => asset.containerId === container.id)
      .sort((a, b) => Date.parse(b.createdOn) - Date.parse(a.createdOn));

    if (matchedAssets?.length > 0) {
      existingObject.assets = matchedAssets;
    }

    return existingObject;
  });
}

function getContainerById(containers, containerId) {
  const matchedContainer = containers.find(
    (container) => container.id === containerId
  );

  if (matchedContainer) {
    return matchedContainer;
  }

  return {};
}

function getAssetByContainerId(containers, { containerId, assetId }) {
  const container = getContainerById(containers, containerId);

  if (container.assets && container.assets.length > 0) {
    if (assetId) {
      const matchedAsset = container.assets.find(
        (asset) => asset.id === assetId
      );

      if (matchedAsset) {
        return matchedAsset;
      }
    } else {
      return container.assets[0];
    }
  }

  return {};
}

export default function TruckImages() {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [currentFilters, setCurrentFilters] = useState([]);
  const [currentlyEditing, setCurrentlyEditing] = useState(null);

  const { setLightboxImages } = useLightbox();

  const { isUploadFinished } = useUploads();

  const { checkAccess, rolesMap } = useRoles();
  const { getPhotoContainers, photoContainers, tags } = usePhotoContainer();
  const {
    refetch,
    clearSelectedItems,
    truckDetails: {
      condition = "",
      vin,
      assets,
      make,
      year,
      model,
      squidexUrl,
    },
  } = usePhotos();

  useEffect(() => {
    getPhotoContainers();
  }, [getPhotoContainers]);

  const mappedPhotos = useMemo(
    () => mapPhotos(photoContainers, assets),
    [photoContainers, assets]
  );

  const featuredImageContainer = useMemo(
    () => photoContainers.find((c) => c.location === "Featured Image"),
    [photoContainers]
  );

  const mainPhotos = useMemo(
    () =>
      mappedPhotos
        //.filter((asset) => asset.location !== "Featured Image")
        .filter((container) => !("numberAllowed" in container))
        .filter((asset) => isAssetVisible(currentFilters, asset)),
    [mappedPhotos, currentFilters]
  );

  const extraPhotos = useMemo(
    () =>
      mappedPhotos
        .filter((container) => "numberAllowed" in container)
        .filter((asset) => isAssetVisible(currentFilters, asset)),
    [mappedPhotos, currentFilters]
  );

  const featuredAsset = useMemo(
    () => !!featuredImageContainer ? assets
      .sort((a, b) => Date.parse(b.createdOn) - Date.parse(a.createdOn))
      .find(a => a.containerId === featuredImageContainer.id) : null,
    [featuredImageContainer, assets]
  )

  const hasBaseGenerationAsset = useMemo(
    () => {
      const baseImageContainer = photoContainers.find(pc => pc.location === '30-Degree Driver Side');
      return !!assets.find(p => p.containerId === baseImageContainer?.id)
    },
    [assets, photoContainers]
  )

  const allowRegenerateFeaturedImage = useMemo(
    () => {
      const baseImageContainer = photoContainers.find(pc => pc.location === '30-Degree Driver Side');
      const baseImage = assets.find(p => p.containerId === baseImageContainer?.id);
      return !!baseImage && !!featuredAsset && baseImage.createdOn > featuredAsset.createdOn;
    },
    [assets, featuredAsset]
  )

  const missingDetails = useMemo(
    () =>
      [!make && "Make", !model && "Model", !vin && "Vin"].filter((val) => val),
    [make, model, vin]
  );

  const clearFilters = () => setCurrentFilters([]);

  const setFilter = (filterId, filterValue) => {
    setCurrentFilters((previousState) => {
      const existingIndex = previousState.findIndex(
        (filter) => filter.id === filterId
      );

      const newObject = { id: filterId, value: filterValue };

      if (existingIndex < 0) {
        return [...previousState, newObject];
      }

      if (previousState[existingIndex].value === filterValue) {
        return previousState;
      }

      let newArray = [...previousState];

      newArray.splice(existingIndex, 1, newObject);

      return newArray;
    });
  };

  useEffect(() => {
    setLightboxImages(assets);
  }, [assets, setLightboxImages]);

  useEffect(() => {
    clearSelectedItems();
  }, [currentFilters, clearSelectedItems]);

  useEffect(() => {
    if (!isUploadFinished) {
      return;
    }

    refetch(vin);
  }, [isUploadFinished, refetch, vin]);

  if (photoContainers.length === 0) {
    return <></>;
  }

  return (
    <>
      <div className="container">
        {checkAccess([rolesMap.marketing, rolesMap.admin]) && (
          <PageReadOnly
            isActive={missingDetails.length > 0}
            squidexLink={squidexUrl}
            missingDetails={missingDetails}
          />
        )}
        <div style={{ display: "flex", justifyContent: "space-between", position: 'relative' }}>
          <div style={{ marginRight: '300px' }}>
            <h2>Inventory images</h2>
            <InventoryActions
              className={cn("inventory-actions")}
              visibleAssets={[...mainPhotos, ...extraPhotos]}
            />

            <div className={cn("inventory-actions")}>
              <Filters
                currentFilters={currentFilters}
                tags={tags}
                setFilter={setFilter}
                clearFilters={clearFilters}
                containers={mappedPhotos}
              />
            </div>
          </div>
          {featuredImageContainer && checkAccess([rolesMap.admin, rolesMap.marketing]) && (
            <TruckImage
              containerId={featuredImageContainer.id}
              containerTags={featuredImageContainer.tags}
              vin={vin}
              condition={condition}
              samples={featuredImageContainer.samples}
              styles={{width: "275px", position: 'absolute', bottom: '0rem', right: '0rem', height: 'auto' }}
              asset={featuredAsset}
              location={featuredImageContainer.location}
              setCurrentlyEditing={setCurrentlyEditing}
              generationOptions={{
                isGenerated: true,
                isAllowed: checkAccess([rolesMap.admin, rolesMap.marketing]),
                disabled: !hasBaseGenerationAsset,
                allowRegenerate: allowRegenerateFeaturedImage
              }}
            />
          )}
        </div>

        {mainPhotos.length > 0 && (
          <div className={cn("inventory-section")}>
            <div className={cn("inventory-section__header")}>
              <h3
                className={cn(
                  "inventory-section__title",
                  "header--lvl-2",
                  "header--dash"
                )}
              >
                Truck walkthrough images
              </h3>

              <div className={cn("inventory-section__actions")}>
                <Button
                  types={["filled", "small"]}
                  onClick={() => setIsDrawerOpen(true)}
                >
                  Use image wizard
                </Button>
              </div>
            </div>

            <div className={cn("photo-grid")}>
              {mainPhotos
                .filter((container) => container.location !== "Featured Image")
                .map((container) => {
                  const thisAsset =
                    container.assets.length > 0 ? container.assets[0] : null;

                  return (
                    <TruckImage
                      key={container.id}
                      containerId={container.id}
                      containerTags={container.tags}
                      vin={vin}
                      location={container.location}
                      condition={condition}
                      asset={thisAsset}
                      samples={container.samples}
                      setCurrentlyEditing={setCurrentlyEditing}
                    />
                  );
                })}
            </div>
          </div>
        )}
      </div>

      {extraPhotos.length > 0 &&
        extraPhotos.map((extra) => (
          <TruckMultiImage
            key={extra.id}
            vin={vin}
            setCurrentlyEditing={setCurrentlyEditing}
            condition={condition}
            {...extra}
          />
        ))}

      <Drawer isOpen={isDrawerOpen} onClose={() => setIsDrawerOpen(false)}>
        <Wizard
          vin={vin}
          steps={mainPhotos.filter(p => p.location !== 'Featured Image')}
          startingStep={Math.max(
            0,
            mainPhotos.findIndex((container) => container.assets?.length === 0)
          )}
          onClose={() => setIsDrawerOpen(false)}
        />
      </Drawer>

      <Drawer
        isOpen={currentlyEditing !== null}
        onClose={() => setCurrentlyEditing(null)}
      >
        <Edit
          vin={vin}
          condition={condition}
          make={make}
          model={model}
          year={year}
          container={
            currentlyEditing
              ? getContainerById(photoContainers, currentlyEditing.containerId)
              : {}
          }
          asset={
            currentlyEditing
              ? getAssetByContainerId(mappedPhotos, currentlyEditing)
              : {}
          }
          onClose={() => setCurrentlyEditing(null)}
          refetch={refetch}
        />
      </Drawer>

      <Lightbox />
    </>
  );
}
