export const availableTags = [
	{ value: '30-degree-right', label: '30 Degree Right Side View' },
	{ value: 'full-front', label: 'Full Front View' },
	{ value: '30-degree-left', label: '30 Degree Left Side View' },
	{ value: 'full-left', label: 'Full Left Side View' },
	{ value: '30-egree-rear-left', label: '30 Degree Rear Left Side View' },
	{ value: 'full-rear', label: 'Full Rear View' },
	{ value: '30-degree-rear-right', label: '30 Degree Rear Right Side View' },
	{ value: 'full-right', label: 'Full Right Side View' },
	{ value: 'rear-frame', label: 'Rear Frame' },
	{ value: 'tires', label: 'Tires (Tread)' },
	{ value: 'engine-driver', label: 'Drivers Side Engine' },
	{ value: 'engine-passenger', label: 'Passenger Side Engine' },
	{ value: 'driver-doors-open', label: 'Drivers Side Doors Open' },
	{ value: 'interior-seats', label: 'Interior Seats' },
	{ value: 'interior-dash', label: 'Interior Dash' },
	{ value: 'interior-cockpit', label: 'Interior Cockpit' },
	{ value: 'interior-radio', label: 'Interior Radio/Navigation System' },
	{ value: 'interior-wheel', label: 'Interior Steering Wheel' },
	{ value: 'interior-mileage', label: 'Interior Mileage' },
	{ value: 'sleeper-wide', label: 'Interior Sleeper Wide View' },
	{ value: 'sleeper-left', label: 'Interior Sleeper Left Side' },
	{ value: 'sleeper-right', label: 'Interior Sleeper Right Side' },
	{ value: 'cargo-space', label: 'Cargo Space, Battery Box, Etc.' },
	{ value: 'vin', label: 'VIN Tag' }
];
