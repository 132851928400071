import { useRef, useState } from "react";
import * as tus from "tus-js-client";
import { toast } from "react-toastify";

import { assetStatuses as statuses } from "../constants/statuses";
import { acquireAccessToken } from "../services/msal";
import { usePhotos } from "./usePhotos";
import {usePhotoUsage} from "./usePhotoUsage";
import {useRoles} from "./useRoles";

const defaultState = {
  key: "",
  id: "",
  contentUrl: "",
  fileName: "",
  fileSize: 0,
  altText: "",
  status: "",
  sortOrder: 0,
  isVertical: false,
  tags: [],
  createdBy: "",
  createdOn: "",
  updatedBy: "",
  updatedOn: "",
  containerId: "",
  progress: "0",
  errorMessage: null,
};

const getItemKey = (index, fileName) => `${index}__${fileName}`;

export function useMultipleUploads(vin, containerId, tags) {
  const [uploads, setUploads] = useState([]);
  const { truckDetails } = usePhotos();
  const { isAppraisalUsage } = usePhotoUsage();
  const { checkAccess, rolesMap } = useRoles();

  const uploadIndex = useRef(0);

  const addUpload = (key, file) => {
    setUploads((previousState) => {
      return [
        ...previousState,
        {
          ...defaultState,
          key: key,
          fileName: file.name,
          fileSize: file.size,
          status: statuses.awaiting,
          vin,
          containerId,
          tags,
        },
      ];
    });
  };

  const updateUpload = (key, updates) => {
    setUploads((previousState) => {
      let previous = [...previousState];
      let itemToUpdateIndex = previousState.findIndex(
        (upload) => upload.key === key
      );
      let itemToUpdate = previousState[itemToUpdateIndex];

      const updatedUpload = { ...itemToUpdate, ...updates };

      previous.splice(itemToUpdateIndex, 1, updatedUpload);

      return previous;
    });
  };

  const startUploads = (files) => {
    uploadIndex.current = 0;

    files.forEach((file) => {
      uploadIndex.current += 1;

      startUpload(file, uploadIndex.current);
    });
  };

  const startUpload = async (file, index) => {
    if (isAppraisalUsage === undefined && checkAccess([ rolesMap.salesSap ])) {
      toast.error("Must select an option for photo usage before uploading.")
      return;
    }
    if (file.size >= 15728640) {
      toast.error(`Uploaded file (${file.name}) exceeded max size of 15MB.`);
      return;
    }
    const itemKey = getItemKey(index, file.name);

    addUpload(itemKey, file);

    const accessToken = await acquireAccessToken();

    var upload = new tus.Upload(file, {
      endpoint: process.env.NEXT_PUBLIC_TUS_ENDPOINT,
      retryDelays: [0, 3000],
      chunkSize: 300 * 1000,
      removeFingerprintOnSuccess: true,
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      metadata: {
        FileName: file.name,
        FileType: file.type,
        Vin: vin,
        ContainerId: containerId,
        Tags: tags,
        IsAppraisalUsage: isAppraisalUsage
      },
      onProgress: function (bytesUploaded, bytesTotal) {
        var percentage = ((bytesUploaded / bytesTotal) * 100).toFixed(2);

        updateUpload(itemKey, {
          status: statuses.pending,
          progress: `${percentage}%`,
        });
      },
      onError: function (error) {
        console.error("There was an error with multiple uploads:", error);

        updateUpload(itemKey, {
          status: statuses.error,
          errorMessage: error,
        });
      },
      onSuccess: function () {
        const assetId = upload.url.split("/image/")[1];

        updateUpload(itemKey, {
          status: statuses.success,
          contentUrl: `/truck/${vin}/assets/${assetId}`,
          progress: null,
          id: assetId,
        });
      },
    });

    upload.findPreviousUploads().then((previousUploads) => {
      if (previousUploads.length) {
        upload.resumeFromPreviousUpload(previousUploads[0]);
      }

      upload.start();
    });

    return upload;
  };

  const clearUploads = () => {
    setUploads([]);
  };

  return {
    startUploads,
    clearUploads,
    assets: uploads,
  };
}
