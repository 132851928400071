import classNames from 'classnames/bind';

import Icon from '../Icon';
import ProgressBar from '../ProgressBar';

import styles from './ImageProgress.module.scss';

const cn = classNames.bind( styles );

export default function ImageProgress({
	progress
}) {
	return (
		<div className={ cn( 'image-progress' ) }>
			<Icon icon="photo" />

			<div className={ cn( 'image-progress__label' ) }>
				<ProgressBar className={ cn( 'image-progress__bar' ) } progress={ progress } />
			</div>
		</div>
	);
}
