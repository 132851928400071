import {createContext, useContext, useEffect, useState} from "react";

const PhotoUsageContext = createContext();

function PhotoUsageProvider({ status, children }) {
  const [ isAppraisalUsage, setIsAppraisalUsage ] = useState();

  useEffect(() => {
    if (status === 'Potential Trade/Purchase' || status === 'Incoming Trade/Purchase')
      setIsAppraisalUsage(true);
    else if (status === 'Stock on Hand')
      setIsAppraisalUsage(false)
  }, [status]);

  const value = {
    isAppraisalUsage,
    setIsAppraisalUsage
  }

  return <PhotoUsageContext.Provider value={value}>{children}</PhotoUsageContext.Provider>
}

function usePhotoUsage() {
  const context = useContext(PhotoUsageContext);

  if (context === undefined) {
    throw new Error('usePhotoUsage must be used within a PhotoUsageProvider');
  }

  return context;
}

export { PhotoUsageProvider, usePhotoUsage };
