import { useEffect, useRef } from 'react';
import { createPortal } from 'react-dom';
import classNames from 'classnames/bind';
import FocusTrap from 'focus-trap-react';

import { CloseButton } from '../Button/Button';

import { useConfirm } from '../../hooks/useConfirm';
import { useLightbox } from '../../hooks/useLightbox';
import { useMountTransition } from '../../hooks/useMountTransition';

import styles from './Drawer.module.scss';

const cn = classNames.bind( styles );

function createPortalRoot() {
	const drawerRoot = document.createElement( 'div' );

	drawerRoot.setAttribute( 'id', 'drawer-root' );

	return drawerRoot;
}

export default function Drawer({
	isOpen,
	children,
	className,
	onClose,
	position = 'right',
	removeWhenClosed = true
}) {
	const { isOpen: isConfirmOpen } = useConfirm();
	const { activeLightbox } = useLightbox();

	const bodyRef = useRef( document.querySelector( 'body' ) );
	const portalRootRef = useRef( document.getElementById( 'drawer-root' ) || createPortalRoot() );

	const isTransitioning = useMountTransition( isOpen, 300 );

	useEffect( () => {
		const updatePageScroll = () => {
			if ( isOpen ) {
				bodyRef.current.style.overflow = 'hidden';
			} else {
				bodyRef.current.style.overflow = '';
			}
		};

		updatePageScroll();
	}, [ isOpen ] );

	useEffect( () => {
		bodyRef.current.appendChild( portalRootRef.current );

		const portal = portalRootRef.current;
		const bodyEl = bodyRef.current;

		return () => {
			portal.remove();
			bodyEl.style.overflow = '';
		};
	}, [] );

	useEffect( () => {
		const onKeyPress = e => {
			if ( e.key === 'Escape' ) {
				onClose();
			}
		}

		if ( isOpen ) {
			window.addEventListener( 'keyup', onKeyPress );
		}

		return () => {
			window.removeEventListener( 'keyup', onKeyPress );
		}
	}, [ isOpen, onClose ] );

	if ( !isTransitioning && removeWhenClosed && !isOpen ) {
		return null;
	}

	return createPortal(
		<FocusTrap active={ !isConfirmOpen && isOpen && activeLightbox < 0 }>
			<div
				aria-hidden={ isOpen ? 'false' : 'true' }
				className={ cn( 'drawer', {
					'drawer--open': isOpen,
					'drawer--in': isTransitioning,
				}, className ) }
			>
				<div className={ cn( 'drawer-content', position ) } role="dialog">
					<div className={ cn( 'drawer-content__header' ) }>
						<CloseButton className={ cn( 'drawer-close' ) } onClick={ onClose } />
					</div>

					{ children }
				</div>

				<div className={ cn( 'drawer-backdrop' ) } onClick={ onClose } />
			</div>
		</FocusTrap>,
		portalRootRef.current
	);
}
