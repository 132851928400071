import { useMemo } from 'react';
import { useDropzone } from 'react-dropzone';
import classNames from 'classnames/bind';

import Icon from '../Icon';

import styles from './Dropzone.module.scss';

const cn = classNames.bind( styles );

const baseStyle = {
	flex: 1,
	display: 'flex',
	flexDirection: 'column',
	alignItems: 'center',
	justifyContent: 'center',
	padding: '30px',
	borderWidth: 2,
	borderColor: '#585858',
	borderStyle: 'dashed',
	color: '#222b3e',
	fontSize: '18px',
	outline: 'none',
	transition: 'border .24s ease-in-out'
};

const focusedStyle = {
	borderColor: '#2196f3'
};

const acceptStyle = {
	borderColor: '#00e676'
};

const rejectStyle = {
	borderColor: '#ff1744'
};

const childrenStyle = {
	borderWidth: 0,
	padding: 0
};

export default function Dropzone({
	className,
	accept = 'image/*,video/*',
	noClick = false,
	onClick = function() {},
	onDrop = function() {},
	onDragEnter = function() {},
	onDragLeave = function() {},
	multiple = true,
	maxFiles,
	children,
	showChildren = false,
	isUploadActive = true
}) {
	const {
		getRootProps,
		getInputProps,
		isFocused,
		isDragAccept,
		isDragReject
	} = useDropzone({
		accept,
		onDrop,
		onDragEnter,
		onDragLeave,
		noClick,
		multiple,
		maxFiles
	});

	const style = useMemo( () => ({
		...baseStyle,
		...( isFocused ? focusedStyle : {} ),
		...( isDragAccept ? acceptStyle : {} ),
		...( isDragReject ? rejectStyle : {} ),
		...( showChildren ? childrenStyle: {} ),
	}), [
		isFocused,
		isDragAccept,
		isDragReject,
		showChildren
	]);

	return (
		<div className={ cn( 'dropzone', className ) } onClick={ onClick }>
			<div { ...getRootProps({ style }) }>
				<input { ...getInputProps() } />

				{ ( !showChildren || isUploadActive ) && (
					<div className={ cn( 'dropzone-area' ) }>
						<Icon icon="upload" />

						<div className={ cn( 'dropzone__label' ) }>Click to select or drag files here</div>
					</div>
				)}

				{ children }
			</div>
		</div>
	);
}
