import { useCallback, useEffect, useMemo, useState } from "react";
import { useSetState } from "react-use";
import * as tus from "tus-js-client";
import { toast } from "react-toastify";

import { assetStatuses as statuses } from "../constants/statuses";
import { useUploads } from "../hooks/useUploads";
import { acquireAccessToken } from "../services/msal";
import { usePhotos } from "./usePhotos";
import {usePhotoUsage} from "./usePhotoUsage";
import {useRoles} from "./useRoles";

const defaultState = {
  id: "",
  contentUrl: "",
  fileName: "",
  fileSize: 0,
  altText: "",
  status: "",
  sortOrder: 0,
  isVertical: false,
  tags: [],
  createdBy: "",
  createdOn: "",
  updatedBy: "",
  updatedOn: "",
  containerId: "",
  progress: "0",
  errorMessage: null,
  isUpload: true,
};

export function useSingleUpload() {
  const [uploadStatus, setUploadStatus] = useSetState({ ...defaultState });
  const { isUploadFinished, addToQueue, removeFromQueue } = useUploads();
  const { truckDetails: { assets } } = usePhotos();
  const [queueString, setQueueString] = useState("");
  const { isAppraisalUsage } = usePhotoUsage();
  const { checkAccess, rolesMap } = useRoles();

  useEffect(() => {
    if (uploadStatus.id === '')
      return;

    const asset = assets?.find(a => a.id === uploadStatus.id);
    if (!asset || uploadStatus.status !== statuses.success || !isUploadFinished) {
      return;
    }

    setUploadStatus({ ...defaultState });
    setQueueString("");
  }, [uploadStatus, isUploadFinished, assets]);

  const clearUpload = useCallback(() => {
    removeFromQueue(queueString);
  }, [queueString, removeFromQueue]);

  const startUpload = useCallback(
    async (file, vin, containerId, containerTags) => {
      if (isAppraisalUsage === undefined && checkAccess([ rolesMap.salesSap ])) {
        toast.error("Must select an option for photo usage before uploading.")
        return;
      }
      if (file.size >= 31_457_280) {
        toast.error("Uploaded file exceeded max size of 15MB.");
        return;
      }
      const queueString = `${containerId}-${vin}-${file.name}`;
      setUploadStatus({
        fileName: file.name,
        fileSize: file.size,
        status: statuses.awaiting,
        containerId
      });

      addToQueue(queueString);
      setQueueString(queueString);

      const accessToken = await acquireAccessToken();

      var upload = new tus.Upload(file, {
        endpoint: process.env.NEXT_PUBLIC_TUS_ENDPOINT,
        retryDelays: [0, 3000],
        chunkSize: 300 * 1000,
        removeFingerprintOnSuccess: true,
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
        metadata: {
          FileName: file.name,
          FileType: file.type,
          Vin: vin,
          ContainerId: containerId,
          Tags: containerTags,
          IsAppraisalUsage: isAppraisalUsage ?? false
        },
        onProgress: function (bytesUploaded, bytesTotal) {
          var percentage = ((bytesUploaded / bytesTotal) * 100).toFixed(2);

          setUploadStatus({
            status: statuses.pending,
            progress: `${percentage}%`,
          });
        },
        onError: function (error) {
          console.log(error);

          setUploadStatus({
            status: statuses.error,
            errorMessage: error,
          });
        },
        onSuccess: function () {
          const assetId = upload.url.split("/image/")[1];

          setUploadStatus({
            status: statuses.success,
            contentUrl: `/truck/${vin}/assets/${assetId}`,
            progress: null,
            id: assetId,
          });
        },
      });

      upload.findPreviousUploads().then((previousUploads) => {
        if (previousUploads.length) {
          upload.resumeFromPreviousUpload(previousUploads[0]);
        }

        upload.start();
      });

      return upload;
    }
  );

  const result = useMemo(
    () => ({
      ...uploadStatus,
    }),
    [uploadStatus]
  );

  return {
    clearUpload,
    startUpload,
    asset: result,
  };
}
