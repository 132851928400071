import { useCallback, useEffect, useState } from "react";
import { useToggle } from "react-use";
import classNames from "classnames/bind";
import { toast } from "react-toastify";
import Loading from "react-loading";

import Button from "../Button";
import TruckPhoto from "../TruckPhoto";

import { assetStatuses as statuses } from "../../constants/statuses";
import { useConfirm } from "../../hooks/useConfirm";
import { usePhotos } from "../../hooks/usePhotos";
import { useSingleUpload } from "../../hooks/useSingleUpload";
import { getThumbnailUrl } from "../../services/images";
import { deleteImages } from "../../services/truckAssets";

import styles from "./Wizard.module.scss";

const cn = classNames.bind(styles);

function WizardStep({
  vin,
  stepData = {},
  steps = [],
  currentPage,
  totalPages,
  onNext,
  onPrevious,
  onClose,
  onSelect,
  nextPageName,
}) {
  const { asset: uploadedAsset, clearUpload, startUpload } = useSingleUpload();
  const { refetch } = usePhotos(vin);
  const { isConfirmed } = useConfirm();

  const [isDeleting, toggleIsDeleting] = useToggle(false);

  const containerId = stepData.id;
  const isLastPage = currentPage >= totalPages;
  const isFirstPage = currentPage === 1;

  const asset = uploadedAsset.status ? uploadedAsset : stepData.assets[0];

  const onDelete = async () => {
    const confirmed = await isConfirmed("delete", [asset.fileName]);

    if (!confirmed) {
      return;
    }

    toggleIsDeleting(true);

    try {
      await deleteImages(vin, [asset.id]);

      await refetch(vin);
    } catch (err) {
      console.error(err);

      toast.error("There was an error deleting this asset.");
    } finally {
      toggleIsDeleting(false);
    }
  };

  const onDrop = useCallback(
    (acceptedFiles) => {
      startUpload(acceptedFiles[0], vin, containerId);
    },
    [startUpload, vin, containerId]
  );

  useEffect(() => {
    if (uploadedAsset.status === statuses.success) {
      clearUpload();
    }
  }, [uploadedAsset, clearUpload]);

  return (
    <div className={cn("wizard-step")}>
      {isDeleting && (
        <div className={cn("wizard__loader")}>
          <Loading type="spin" color="#676767" width={64} height={64} />
        </div>
      )}

      <div className={cn("wizard-step__header")}>
        <h2 className={cn("wizard-step__name")}>{stepData.location}</h2>

        <div className={cn("wizard-step__progress")}>
          {currentPage} of {totalPages}
        </div>
      </div>

      <div className={cn("wizard-step__upload")}>
        <TruckPhoto
          asset={asset}
          onDrop={onDrop}
          containerId={stepData.id}
          showUploadIfEmpty={true}
          allowUploadCancel={false}
          showPlaceholder={false}
        />
      </div>

      <div className={cn("wizard-step__actions")}>
        {!isLastPage && (
          <Button types={["filled"]} onClick={onNext}>
            Next
          </Button>
        )}

        {!isFirstPage && (
          <Button types={["outline"]} onClick={onPrevious}>
            Previous
          </Button>
        )}

        <Button types={["outline"]} onClick={onClose}>
          Close
        </Button>

        {asset && asset.status !== statuses.approved && (
          <Button types={["text", "alert"]} onClick={onDelete}>
            Delete
          </Button>
        )}

        {steps.length > 0 && (
          <div className={cn("wizard-step__next")}>
            <label htmlFor="wizard-jump">Jump to: </label>

            <select
              id="wizard-jump"
              className={cn("wizard-step__select")}
              onChange={onSelect}
            >
              <option value="">Please select...</option>

              {steps.map((step, idx) => (
                <option key={step.id} value={idx}>
                  {step.name}
                </option>
              ))}
            </select>
          </div>
        )}
      </div>

      {!isLastPage && (
        <div className={cn("wizard-step__next-up")}>
          Next up: <strong>{nextPageName}</strong>
        </div>
      )}

      {stepData?.samples?.length > 0 && (
        <div className={cn("wizard-content")}>
          <h2>Samples</h2>

          <div className={cn("wizard-samples")}>
            {stepData.samples.map((sample, idx) => (
              <img
                className={cn("wizard-samples__image")}
                key={idx}
                src={getThumbnailUrl(sample.thumbnailUrl)}
                alt="Sample image"
              />
            ))}
          </div>
        </div>
      )}

      {stepData?.instructions && (
        <div className={cn("wizard-content")}>
          <h2>Instructions and tips</h2>

          <ul>
            {stepData.instructions.split("\n").map((instruction, idx) => (
              <li key={idx}>{instruction}</li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
}

export default function Wizard({ vin, steps, startingStep = 0, onClose }) {
  const [activeStep, setActiveStep] = useState(startingStep);

  const onNext = useCallback(() => {
    if (activeStep + 1 >= steps.length) {
      return;
    }

    setActiveStep(activeStep + 1);
  }, [activeStep, steps, setActiveStep]);

  const onPrevious = useCallback(() => {
    if (activeStep - 1 < 0) {
      return;
    }

    setActiveStep(activeStep - 1);
  }, [activeStep, setActiveStep]);

  const onSelect = useCallback(
    (event) => {
      if (event.target.value === "") {
        return;
      }

      setActiveStep(Number(event.target.value));
    },
    [setActiveStep]
  );

  const getNextPageName = useCallback(() => {
    if (steps[activeStep + 1]) {
      return steps[activeStep + 1].location;
    }

    return "";
  }, [activeStep, steps]);

  return (
    <div className={cn("wizard")}>
      <h1 className={cn("wizard__title")}>Step-by-step wizard</h1>

      <WizardStep
        vin={vin}
        onNext={onNext}
        onPrevious={onPrevious}
        onSelect={onSelect}
        stepData={steps[activeStep]}
        steps={steps.map((step) => ({ id: step.id, name: step.location }))}
        name={steps[activeStep]?.name}
        currentPage={activeStep + 1}
        totalPages={steps.length}
        onClose={onClose}
        nextPageName={getNextPageName()}
      />
    </div>
  );
}
