import { useEffect, useState } from "react";
import Router, { useRouter } from "next/router";
import Loading from "react-loading";

import Layout from "../../components/Layout";
import TruckImages from "../../components/TruckImages";

import { usePhotos } from "../../hooks/usePhotos";
import { PhotoUsageForm } from "../../components/PhotoUsageForm/PhotoUsageForm";
import {useRoles} from "../../hooks/useRoles";
import {roles} from "../../constants/roles";
import {PhotoUsageProvider} from "../../hooks/usePhotoUsage";

function ItemData({ label, value }) {
  if (!value) {
    return <></>;
  }

  return (
    <div className="item-data">
      <h3 className="item-data__label">{label}</h3>

      <div className="item-data__value">{value}</div>
    </div>
  );
}

export function TruckDetails() {
  const {
    query: { truckId },
  } = useRouter();
  const { loading, getTruckDetails, truckDetails } = usePhotos();
  const { checkAccess } = useRoles();

  useEffect(() => {
    if (truckId) {
      getTruckDetails(truckId);
    }
  }, [getTruckDetails, truckId]);

  if (!truckDetails || loading)
    return (
      <Layout breadcrumbs={[]}>
        <div className="container">
          <Loading className="page-loader" type="spin" color="#676767" />
        </div>
      </Layout>
    );

  const {
    vin,
    stockNumber,
    make,
    model,
    year,
    isWholesale,
    condition,
    tradePackage,
    sellingFeatures,
    appraisalStatus,
  } = truckDetails;

  return (
    <Layout
      breadcrumbs={[
        { id: "breadcrumb-home", label: "Home", href: "/" },
        { id: "breadcrumb-vin", label: vin },
      ]}
    >
      <PhotoUsageProvider status={truckDetails.status}>
        <div className="truck-detail">
          <header className="truck-detail__header container">
            <h1 className="truck-detail__title">
              {stockNumber} &ndash; {year} {make} {model}{" "}
              <span className="truck-detail__sub">{condition}</span>
            </h1>
            {condition === 'Appraisal' && appraisalStatus === 'Appraisal Requested' && (
              <p style={{ color: "red", fontWeight: 'bold' }}>This truck is in for appraisal</p>
            )}

            <a className="link--underline" href="#truck-details">
              See all truck details
            </a>

            { checkAccess([roles.salesSap]) && (
              <PhotoUsageForm />
            )}
          </header>

          <TruckImages />

          <div id="truck-details" className="container">
            <div className="item-details">
              <h2>Truck Details</h2>

              <ItemData label="Status" value={condition} />
              <ItemData label="Vin" value={vin} />
              <ItemData label="Unit stock number" value={stockNumber} />
              <ItemData label="Year" value={year} />
              <ItemData label="Make" value={make} />
              <ItemData label="Model" value={model} />
              <ItemData label="Trade package" value={tradePackage} />
              <ItemData label="Selling features" value={sellingFeatures} />
            </div>
          </div>
        </div>
      </PhotoUsageProvider>
    </Layout>
  );
}

export default function TruckDetailsWrapper() {
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    const { pathname } = Router;

    if (pathname === "/guidelines") {
      Router.push("/guidelines");
    } else {
      setLoaded(true);
    }
  }, []);

  if (!loaded) {
    return <></>;
  }

  return <TruckDetails />;
}
