import { useState } from "react";
import {usePhotoUsage} from "../../hooks/usePhotoUsage";
import Button from "../Button";
import styles from './PhotoUsageForm.module.scss'

export function PhotoUsageForm() {
  const { isAppraisalUsage, setIsAppraisalUsage } = usePhotoUsage()

  return (
    <>
      <div className={styles.form}>
        <p>Are these photos for:</p>
          <Button
            types={isAppraisalUsage !== undefined && !isAppraisalUsage ? ['filled'] : ['outline']}
            onClick={() => setIsAppraisalUsage(false)}>
            Stock On Hand (Website)
          </Button>
          <Button
            types={isAppraisalUsage ? ['filled'] : ['outline']}
            onClick={() => setIsAppraisalUsage(true)}>
            Appraisal/Wholesale
          </Button>
        </div>
        <div>
          {isAppraisalUsage === undefined && (
            <span style={{ color: 'rgb(224, 60, 63)', fontWeight: "bold", marginTop: '1rem' }}>Must be selected before uploading new images</span>
          )}
        </div>
    </>
  );
}
