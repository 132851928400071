export function getImageUrl( path, width = 500 ) {
	const widthQuery = width ? `?w=${ width }` : '';

	return `${ process.env.NEXT_PUBLIC_ASSETS_ENDPOINT }${ path }${ widthQuery }`;
}

export function getVideoUrl( path ) {
	return `${ process.env.NEXT_PUBLIC_ASSETS_ENDPOINT }${ path }`;
}

export function getAssetId( assetId, contentUrl ) {
	if ( assetId ) {
		return assetId;
	}

	if ( contentUrl ) {
		return contentUrl.split( '/assets/' )[ 1 ];
	}

	return '';
}

export function isVideo( fileName ) {
	const extension = fileName.split( '.' ).slice( -1 )[ 0 ];

	const videoExtensions = [ 'mov', 'mp4', 'ogg', 'webm', 'mpg', 'm4v' ];

	if ( extension ) {
		return videoExtensions.includes( extension.toLowerCase() );
	}

	return false;
}

export function getThumbnailUrl( url ) {
	if ( !url ) {
		return;
	}

	const thumbnail = new URL( url );
	const query = new URLSearchParams( thumbnail.search );

	query.set( 'width', '500' );

	return `${ thumbnail.origin }${ thumbnail.pathname }?${ query.toString() }`;
}

export function splitFilename( fileName ) {
	let returnObj = { filename: '', extension: '' };

	if ( !fileName ) {
		return returnObj;
	}

	let split = fileName.split( '.' );
	let extension = split.pop();

	returnObj = { filename: split.join( '.' ), extension };

	return {
		filename: split.join( '.' ),
		extension
	};
}

export function getSquidexUrl( url, options ) {
	const searchParams = new URLSearchParams( options );

	return `${ url }?${ searchParams.toString() }`;
}
