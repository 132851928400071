import classNames from 'classnames/bind';

import Button from '../Button';
import Notice from '../Notice';
import Tag, { TagList } from '../Tag';
import TagSelector from '../TagSelector';

import { assetStatuses as statuses, truckConditions as truckStatuses } from '../../constants/statuses';

import styles from './EditForm.module.scss';

const cn = classNames.bind( styles );

function EditTags({
	id,
	value = [],
	onChange,
	isReadonly = false
}) {
	if ( isReadonly ) {
		return (
			<TagList>
				{ value.length > 0 && value.map( tag => (
					<Tag key={ tag.value } value={ tag.value } />
				))}
			</TagList>
		);
	}

	return (
		<TagSelector
			id={ id }
			instanceId={ id }
			value={ value }
			onChange={ onChange }
		/>
	);
}

export function EditForm({
	condition,
	children,
	onApprove,
	onCancel,
	onDelete,
	onDownload,
	onRequestRetake,
	onSave,
	status,
	isLoading
}) {
	return (
		<>
			{ status === statuses.approved && condition !== truckStatuses.appraisal && (
				<Notice>This image is approved and has been published. Any required changes must be made in Squidex.</Notice>
			)}

			<ul className={ cn( 'edit-form' ) }>
				{ children }

				{ status !== statuses.pending && status !== statuses.awaiting && status !== statuses.success && (
					<>
						<li className={ cn( 'edit-form__actions' ) }>
							{ status !== statuses.approved && condition !== truckStatuses.appraisal && <Button types={[ 'filled' ]} onClick={ onSave } isDisabled={ isLoading }>Save edits</Button> }

							{ status !== statuses.approved && condition !== truckStatuses.appraisal && <Button types={[ 'outline' ]} onClick={ onApprove } isDisabled={ isLoading }>Approve for web</Button> }

							<Button types={[ 'outline' ]} icon="download" onClick={ onDownload } isDisabled={ isLoading }>Download</Button>

							{ status !== statuses.approved && condition !== truckStatuses.appraisal && <Button types={[ 'outline' ]} onClick={ onRequestRetake } isDisabled={ isLoading || status === statuses.retake }>Request retake</Button> }

							<Button types={[ 'outline' ]} onClick={ onCancel } isDisabled={ isLoading }>{ status === statuses.approved || condition === truckStatuses.appraisal ? 'Close' : 'Cancel' }</Button>
						</li>

						{ condition !== truckStatuses.appraisal && (
							<li className={ cn( 'edit-form__delete' ) }>
								<Button types={[ 'text', 'alert' ]} onClick={ onDelete } isDisabled={ isLoading }>Delete</Button>
							</li>
						)}
					</>
				)}
			</ul>
		</>
	);
}

export function EditInput({
	label = '',
	id = '',
	type = 'text',
	value = '',
	onChange = function() {},
	note = null,
	isReadonly = false,
	register = () => {},
	required = false,
	errors
}) {
	return (
		<li className={ cn( 'edit-form__item' ) }>
			{ label && type !== 'tags' && <label className={ cn( 'edit-form__label' ) } htmlFor={ id }>{ label }</label> }

			{ type === 'tags' && (
				<>
					<label className={ cn( 'edit-form__label' ) } htmlFor={ `react-select-${ id }-input` }>{ label }</label>

					<EditTags
						id={ id }
						value={ value }
						onChange={ onChange }
						isReadonly={ isReadonly }
					/>
				</>
			 )}

			{ !isReadonly && type === 'text' && (
				<input className={ cn( 'edit-form__field', { 'edit-form__field--error': errors[ id ] } ) } { ...register( id, { required } ) } id={ id } type={ type } />
			)}

			{ isReadonly && type !== 'tags' && (
				<span className={ cn( 'edit-form__value' ) }>{ value }</span>
			)}

			{ type === 'note' && note && <span className={ cn( 'approval-form__note' ) }>{ note }</span> }
		</li>
	);
}
