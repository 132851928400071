import {useCallback, useEffect, useRef, useState} from "react";
import { useToggle } from "react-use";
import classNames from "classnames/bind";
import { toast } from "react-toastify";

import Button from "../Button";
import Dropzone from "../Dropzone";
import Icon from "../Icon";
import ImageProgress from "../ImageProgress";

import {
  assetStatuses as statuses,
  assetStatusMessages as statusMessages,
} from "../../constants/statuses";
import { useLightbox } from "../../hooks/useLightbox";
import { getImageUrl, getThumbnailUrl } from "../../services/images";
import {deleteImage, generateFeaturedImage} from "../../services/truckAssets";

import styles from "./TruckPhoto.module.scss";
import Loading from "react-loading";
import {usePhotoUsage} from "../../hooks/usePhotoUsage";

const cn = classNames.bind(styles);

function SampleImage({ url }) {
  return (
    <div className={cn("sample-image")}>
      <div className={cn("sample-image__overlay")}>
        <span>Sample</span>
      </div>

      <img
        className={cn("sample-image__image")}
        src={getThumbnailUrl(url)}
        alt="Sample image"
      />
    </div>
  );
}

function Placeholder() {
  return (
    <div className={cn("no-image")}>
      <Icon icon="photo" width={100} height={100} />

      <p className={cn("no-image__text")}>No images available</p>
    </div>
  );
}

function NoUpload({ samples, showPlaceholder }) {
  const sample = samples.length > 0 ? samples[0] : null;

  return (
    <>
      {sample && <SampleImage url={sample.thumbnailUrl} />}

      {!sample && showPlaceholder && <Placeholder />}
    </>
  );
}

function AssetStatus({ status, allowRegenerate }) {
  const getStatusKey = (status) => {
    return Object.keys(statuses).find((key) => statuses[key] === status);
  };

  const statusBanners = Object.entries(statusMessages).map(
    ([key]) => statuses[key]
  );
  const statusKey = getStatusKey(status);

  if (!statusBanners.includes(status)) {
    return <></>;
  }

  return (
    <div
      className={cn("truck-image__status", `truck-image__status--${statusKey}`)}
    >
      {allowRegenerate && <span>* </span>}{statusMessages[statusKey]}
    </div>
  );
}

export default function TruckPhoto({
  asset,
  samples = [],
  containerId,
  onDrop = function () {},
  className = "",
  showUploadIfEmpty = false,
  allowUploadCancel = true,
  showPlaceholder = true,
  hidePhotoFunctions = false,
  generationOptions
}) {
  const [isUploadActive, toggleUploadActive] = useToggle(false);
  const [isGenerating, setIsGenerating] = useState(false);
  const { isAppraisalUsage } = usePhotoUsage();

  const { openLightbox } = useLightbox();

  const getUploadedImage = useCallback(() => {
    if (asset && asset.contentUrl) {
      return getImageUrl(asset.contentUrl);
    }

    return null;
  }, [asset]);

  const getUploadText = useCallback(
    (imageUrl) => {
      if (imageUrl) {
        return isUploadActive ? "Cancel" : "Replace image";
      }

      return isUploadActive ? "Cancel" : "Upload image";
    },
    [isUploadActive]
  );

  const getGenerateText = useCallback(
    (imageUrl) => {
      if (imageUrl) {
        return isUploadActive ? "Cancel" : generationOptions?.allowRegenerate ? 'Regenerate' : "Replace image";
      }
      return "Generate"
    },
    [isUploadActive, generationOptions]
  )

  const handleGenerate = async () => {
    setIsGenerating(true);
    (!imageUrl || generationOptions?.allowRegenerate) ? await generationOptions?.onGenerate() : toggleUploadActive();
    setIsGenerating(false);
  }

  const imageUrl = getUploadedImage();
  const showProgress =
    asset?.status === statuses.awaiting || asset?.status === statuses.pending;

  const isButtonVisible = useCallback(() => {
    if (!asset) {
      if (allowUploadCancel) {
        return true;
      } else {
        return false;
      }
    }

    return true;
  }, [asset, allowUploadCancel]);

  useEffect(() => {
    if (showUploadIfEmpty && !imageUrl) {
      toggleUploadActive(true);
    } else {
      toggleUploadActive(false);
    }
  }, [imageUrl, showUploadIfEmpty, toggleUploadActive]);

  return (
    <>
      <div className={cn("truck-image__media", className.split(" "))}>
        <Dropzone
          onClick={() => toggleUploadActive(true)}
          className={cn("dropzone--image")}
          onDrop={async (acceptedFiles) => {
            if (generationOptions?.isGenerated && !imageUrl) {
              return;
            }
            if (acceptedFiles.length === 0) {
              return;
            }

            if (asset?.contentUrl && (asset.status !== statuses.approved || isAppraisalUsage)) {
              const urlComponents = asset.contentUrl.split("/");
              const vin = urlComponents[2];
              const assetId = urlComponents[urlComponents.length - 1];

              try {
                await deleteImage(vin, assetId);
              } catch (err) {
                console.error("Error deleting existing image", err);

                toast.error("Error deleting existing image.");
              }
            }

            toggleUploadActive(false);
            onDrop(acceptedFiles);
          }}
          containerId={containerId}
          multiple={false}
          showChildren={true}
          onDragEnter={() => {
            if (generationOptions?.isGenerated && !imageUrl) return;
            toggleUploadActive(true);
          }}
          onDragLeave={() => {
            if (generationOptions?.isGenerated && !imageUrl) return;
            toggleUploadActive(false);
          }}
          isUploadActive={isUploadActive}
        >
          {(!imageUrl || showProgress) && (
            <NoUpload samples={samples} showPlaceholder={showPlaceholder}/>
          )}

          {showProgress && (
            <div className={cn("truck-image__progress")}>
              <ImageProgress progress={asset?.progress}/>
            </div>
          )}

          {isGenerating && (
            <div className={cn("truck-image__loading")}>
              <Loading color={'gray'} type={"spin"}/>
            </div>
          )}

          {imageUrl && (
            <div className={cn("truck-image__photo-wrapper")}>
              <AssetStatus status={asset?.status} allowRegenerate={generationOptions?.allowRegenerate}/>

              {/* <div className={ cn( 'truck-image__photo-hover' ) }>
									<Icon icon="upload" color="#fff" />
								</div> */}

              <img
                className={cn("truck-image__photo")}
                src={imageUrl}
                alt={asset.altText}
              />
            </div>
          )}
        </Dropzone>
        {/* )} */}
      </div>

      <div
        className={cn("truck-image__actions", {
          "truck-image__actions--hidden": showProgress || hidePhotoFunctions,
        })}
      >
        {imageUrl && (
          <Button
            types={["text", "text-small"]}
            onClick={() => openLightbox(asset.contentUrl)}
          >
            View full-size
          </Button>
        )}

        {isButtonVisible() && !generationOptions?.isGenerated && (
          <Button
            types={["text", "text-small"]}
            onClick={() => toggleUploadActive()}
          >
            {getUploadText(imageUrl)}
          </Button>
        )}

        {generationOptions?.isGenerated && !generationOptions?.disabled && generationOptions.isAllowed && (
          <Button
            types={["text", "text-small"]}
            onClick={() => handleGenerate()}
            >
            {getGenerateText(imageUrl)}
          </Button>
        )}
      </div>
    </>
  );
}
