import { useRouter } from "next/router";
import FocusTrap from "focus-trap-react";
import classNames from "classnames/bind";

import Button, { ButtonLink } from "../Button";

import styles from "./PageReadOnly.module.scss";

const cn = classNames.bind(styles);

export default function PageReadOnly({
  isActive = false,
  missingDetails,
  squidexLink,
}) {
  const router = useRouter();

  if (!isActive) {
    return <></>;
  }

  return (
    <FocusTrap active={true}>
      <div className={cn("read-only")}>
        <div className={cn("read-only__message")}>
          <h2 className={cn("read-only__title")}>
            Truck requires additional/corrected information
          </h2>

          <p>
            Before images can be managed and approved for the web, please add or
            correct the following details for this truck in SAP:
          </p>

          <ul>
            {missingDetails.map((value) => (
              <li key={value}>{value}</li>
            ))}
          </ul>

          <div className={cn("read-only__actions")}>
            {squidexLink && (
              <ButtonLink
                types={["filled"]}
                href={squidexLink}
                newWindow={true}
              >
                Edit in Squidex
              </ButtonLink>
            )}

            <Button types={["outline"]} onClick={() => router.push("/")}>
              Back to dashboard
            </Button>
          </div>
        </div>
      </div>
    </FocusTrap>
  );
}
